import { ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback, Checkbox, Input } from '@boopos/design-system';

import { LinkedInCVInput } from 'components/LinkedInCVInput';

import { useUser } from 'providers/UserProvider';

import { IProfileForm } from './ProfileForm.interface';
import { parseFromApi, parseToApi } from './ProfileForm.utils';

interface IProfileFormComponent {
  onSuccess?: () => Promise<void> | void;
  saveButtonChildren?: ReactNode;
  saveButtonRightIcon?: ReactNode;
}

const ProfileForm = ({
  onSuccess,
  saveButtonChildren,
  saveButtonRightIcon,
}: IProfileFormComponent) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { t } = useTranslation();
  const { user, updateUser } = useUser();

  const formMethods = useForm<IProfileForm>({
    defaultValues: parseFromApi(user),
  });

  const { register, formState, handleSubmit, reset } = formMethods;

  const onSubmit = useCallback(
    async (data: IProfileForm) => {
      await updateUser(parseToApi(data));
      await onSuccess?.();
      reset(data);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 1000);
    },
    [updateUser, reset, onSuccess]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Input
          label={t('ProfileScreen:name_label')}
          variant={
            !!formState?.errors?.firstName?.message ? 'error' : undefined
          }
          subtext={formState?.errors?.firstName?.message}
          id="profileFormFirstName"
          {...register('firstName', {
            required: t('ProfileScreen:name_required_error'),
          })}
        />
        <Input
          label={t('ProfileScreen:last_name_label')}
          variant={formState?.errors?.lastName?.message ? 'error' : undefined}
          subtext={formState?.errors?.lastName?.message}
          id="profileFormLastName"
          {...register('lastName', {
            required: t('ProfileScreen:last_name_required_error'),
          })}
        />
        <LinkedInCVInput />
        <Checkbox
          id="profileFormMarketingOptIn"
          {...register('marketingOptIn')}
          label={t('ProfileScreen:marketing_opt_in')}
        />
        <ButtonFeedback
          type="submit"
          fullWidth
          isLoading={formState.isSubmitting}
          isSuccess={isSuccess}
          rightIcon={saveButtonRightIcon}
        >
          {saveButtonChildren ?? t('ProfileScreen:save_action')}
        </ButtonFeedback>
      </form>
    </FormProvider>
  );
};

export { ProfileForm };
