import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routePaths } from 'router/routePaths';

import { AvatarAlert } from './AvatarAlert';
import { ListBusinessMenuItem } from './ListBusinessMenuItem';
import { IMainMenu } from './MainMenu.interface';
import { Menu } from './Menu.styled';
import { MenuItem } from './MenuItem.styled';
import { MenuItemNavLink } from './MenuItemNavLink.styled';
import { MenuItemText } from './MenuItemText.styled';
import { Avatar, Nav } from './Nav.styled';
import { PortfolioMenuItem } from './PortfolioMenuItem';
import { useAuth0PictureUrl } from './useAuth0PictureUrl';

const MainMenu: FC<IMainMenu> = ({ ...props }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const state = { from: location.pathname };

  const pictureUrl = useAuth0PictureUrl();

  return (
    <Nav {...props}>
      <Avatar.Container>
        <Avatar.Wrapper to={routePaths.account} state={state}>
          <AvatarAlert />
          <Avatar.AvatarCrop>
            {pictureUrl ? (
              <Avatar.Avatar src={pictureUrl} />
            ) : (
              <FontAwesomeIcon icon={regular('user')} />
            )}
          </Avatar.AvatarCrop>
        </Avatar.Wrapper>
      </Avatar.Container>
      <Menu>
        <MenuItem>
          <MenuItemNavLink to={routePaths.forSale} data-testid="listings">
            <>
              <FontAwesomeIcon icon={regular('magnifying-glass')} />

              <MenuItemText>{t('MainMenu:for_sale')}</MenuItemText>
            </>
          </MenuItemNavLink>
        </MenuItem>
        <MenuItem>
          <MenuItemNavLink to={routePaths.dealFlow} data-testid="watchlist">
            <>
              <FontAwesomeIcon icon={regular('bookmark')} />
              <MenuItemText>{t('MainMenu:deal_flow')}</MenuItemText>
            </>
          </MenuItemNavLink>
        </MenuItem>

        <PortfolioMenuItem />
        <ListBusinessMenuItem />
      </Menu>
    </Nav>
  );
};

export default MainMenu;
