import { styled } from '@boopos/design-system';

export const Styles = {
  GridWrapper: styled('div', {
    display: 'grid',
    gap: '1.6rem',
    gridTemplateColumns: 'repeat(12, 1fr)',
  }),
  Input: styled('input', {
    opacity: 0,
    transform: 'scale(0)',
    position: 'absolute',
    top: '-99999px',
    '&:checked + label': {
      backgroundColor: '$slate200',
      borderColor: '$slate600',
      '&:hover': {
        backgroundColor: '$slate300',
      },
    },
    '&:focus + label': {
      borderColor: '$slate400',
    },
    '&:focus:checked + label': {
      borderColor: '$slate700',
    },
  }),
  Label: styled('label', {
    border: '1px solid $slate50',
    background: '$slate50',
    borderRadius: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '7.3rem',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: '$slate100',
    },
    img: {
      maxWidth: '7.1rem',
      maxHeight: '4rem',
    },
  }),
  LabelWrapper: styled('div', {
    gridColumn: 'span 4',
  }),
};
