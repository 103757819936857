import { styled } from '@boopos/design-system';

export const BannerStyles = {
  Wrapper: styled('div', {
    borderRadius: '1.6rem',
    display: 'flex',
    alignItems: 'flex-start',
    color: '$textBodyPrimary',
    p: '1.6rem',
    '@bp2': {
      alignItems: 'center',
      p: '2.4rem',
    },
    variants: {
      variant: {
        default: {
          backgroundColor: '$surfacePromoted',
        },
        warning: {
          backgroundColor: '$surfaceWarning',
        },
        error: {
          backgroundColor: '$surfaceNegative',
        },
        secondary: {
          background: '$surfaceSubdued',
        },
      },
      hasError: {
        true: {
          border: '2px solid $infoNegative',
        },
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }),
  Icon: styled('div', {
    fontSize: '2.4rem',
    lineHeight: '3.2rem',
    mr: '$4',
  }),
  Container: styled('div', {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '$6',
    '@bp2': {
      alignItems: 'center',
      flexDirection: 'row',
    },
  }),
  Title: styled('div', {
    fontWeight: '$extraBold',
    color: '$textBodyPrimary',
    mb: '$2',

    '@bp2': {
      mb: '$1',
    },
  }),
  Content: styled('div', {
    flex: 1,
  }),
  Actions: styled('div', {
    textAlign: 'right',
    display: 'flex',
    gap: '1rem',
    '> button, > a': {
      flex: 1,
    },
  }),
};
