import { useTranslation } from 'react-i18next';

import { DealStructureDetails } from 'components/DealTimeline/DealStructureDetails';
import {
  useSubmitForEvaluationError,
  useSubmitForEvaluationStep,
} from 'components/SubmitForEvaluation';

import { ILoanApi } from 'lib/types';

interface UseDealStructureProps {
  path: string;
  loan: ILoanApi;
  editable: boolean;
}

export const useDealStructureProps = ({
  path,
  loan,
  editable,
}: UseDealStructureProps) => {
  const { t } = useTranslation();

  const { dealStructure } = useSubmitForEvaluationError();

  const errorMessage = dealStructure
    ? t('BasicInformationDetails:deal_structure_required')
    : undefined;

  const { purchaseDealStructure } = loan;

  const { contentCellProps, hasValue } = useSubmitForEvaluationStep({
    path,
    editable,
    entity: purchaseDealStructure,
    actionLiteral: t('BasicInformationDetails:set'),
    hasValueGetter: (entity) => !!entity,
    error: errorMessage,
  });

  return {
    contentCellProps,
    children: hasValue ? (
      <DealStructureDetails purchaseDealStructure={purchaseDealStructure} />
    ) : (
      errorMessage
    ),
  };
};
