import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Banner } from 'ui/Banner';

import {
  QualificationStatusApi,
  useQualificationStatus,
} from 'components/QualificationStatus';

import { routePaths } from 'router/routePaths';

import { QualificationFlowButton } from './QualificationFlowButton';

interface CustomLiterals {
  emptyDescription?: ReactNode;

  underReviewDescription?: ReactNode;
}

export const QualificationBanner = ({
  multiple = false,
  hasError = false,
  customLiterals,
}: {
  multiple?: boolean;
  customLiterals?: CustomLiterals;
  hasError?: boolean;
}) => {
  const { t } = useTranslation();
  const { status } = useQualificationStatus();
  const count = multiple ? 2 : 1;

  const accountActionProps = {
    as: Link,
    to: routePaths.accountQualification,
    'data-testid': 'qualify-to-account',
  };

  if (status === QualificationStatusApi.EMPTY) {
    const description = customLiterals?.emptyDescription ?? (
      <Trans i18nKey="QualificationBanner:unlock_deals" count={count}>
        <strong />
      </Trans>
    );

    return (
      <Banner
        css={{ my: '3.2rem' }}
        description={description}
        icon={<>🔒</>}
        data-testid="banner-empty"
        variant="secondary"
        hasError={hasError}
      >
        <QualificationFlowButton />
      </Banner>
    );
  }

  if (status === QualificationStatusApi.UNDER_REVIEW) {
    const description =
      customLiterals?.underReviewDescription ??
      t('QualificationBanner:under_review_message');

    return (
      <Banner
        css={{ my: '3.2rem' }}
        title={t('QualificationBanner:under_review_heading')}
        description={description}
        icon={<>⏳</>}
        data-testid="banner-under-review"
        variant="secondary"
        hasError={hasError}
      />
    );
  }

  if (status === QualificationStatusApi.MISSING_INFO) {
    return (
      <Banner
        css={{ my: '3.2rem' }}
        variant="warning"
        title={t('QualificationBanner:missing_info')}
        description={t('QualificationBanner:missing_info_description')}
        icon={<>👉</>}
        data-testid="banner-missing-info"
        hasError={hasError}
      >
        <Button {...accountActionProps}>
          {t('QualificationBanner:update')}
        </Button>
      </Banner>
    );
  }

  if (status === QualificationStatusApi.REJECTED) {
    return (
      <Banner
        css={{ my: '3.2rem' }}
        variant="error"
        icon={<>❌</>}
        description={t('QualificationBanner:rejected')}
        data-testid="banner-rejected"
        hasError={hasError}
      >
        <Button {...accountActionProps}>
          <FontAwesomeIcon icon={regular('arrow-right')} />
        </Button>
      </Banner>
    );
  }

  return null;
};
