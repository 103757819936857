import { useTranslation } from 'react-i18next';

import { TimelineItemNowProps } from 'components/DealTimeline/DealTimeline.interface';
import { stepAnimatedProps } from 'components/DealTimeline/StepAnimation';
import { DealTimelineStep } from 'components/DealTimelineStep';

import { ILoanApi, LoanStatus } from 'lib/types';

export const shouldShowDealBasicInformation = (loan: ILoanApi) => {
  return [LoanStatus.missing_info].includes(loan?.status) && !loan?.notes;
};

export const DealBasicInformation = ({
  children,
  loan,
}: TimelineItemNowProps) => {
  const { t } = useTranslation();

  if (!shouldShowDealBasicInformation(loan)) {
    return null;
  }

  return (
    <DealTimelineStep
      {...stepAnimatedProps}
      title={t('DealTimelineStep:submit_target_title')}
      subtitle={t('DealTimelineStep:submit_target_description')}
      data-testid="deal-basic-information"
    >
      {children}
    </DealTimelineStep>
  );
};
