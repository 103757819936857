var K = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function q(s) {
  return s && s.__esModule && Object.prototype.hasOwnProperty.call(s, "default") ? s.default : s;
}
var at = function(r) {
  return r.charAt(0) === "#" ? r.slice(1) : r;
}, ot = function(r) {
  var c = r.length === 3 || r.length === 4, l = c ? "".concat(r.slice(0, 1)).concat(r.slice(0, 1)) : r.slice(0, 2), p = c ? "".concat(r.slice(1, 2)).concat(r.slice(1, 2)) : r.slice(2, 4), v = c ? "".concat(r.slice(2, 3)).concat(r.slice(2, 3)) : r.slice(4, 6), D = (c ? "".concat(r.slice(3, 4)).concat(r.slice(3, 4)) : r.slice(6, 8)) || "ff";
  return {
    r: l,
    g: p,
    b: v,
    a: D
  };
}, X = function(r) {
  return parseInt(r, 16);
}, ut = function(r) {
  var c = r.r, l = r.g, p = r.b, v = r.a;
  return {
    r: X(c),
    g: X(l),
    b: X(p),
    a: +(X(v) / 255).toFixed(2)
  };
}, ct = function(r) {
  return !isNaN(parseFloat(r)) && isFinite(r);
}, ft = function(r, c) {
  var l = r.r, p = r.g, v = r.b, D = r.a, $ = ct(c) ? c : D;
  return "rgba(".concat(l, ", ").concat(p, ", ").concat(v, ", ").concat($, ")");
}, lt = function(r, c) {
  var l = at(r), p = ot(l), v = ut(p);
  return ft(v, c);
}, ht = lt;
const dt = /* @__PURE__ */ q(ht), bt = (s, r = 1) => ({
  background: dt(s, r),
  color: s
});
var rt = { exports: {} };
(function(s, r) {
  (function(c, l) {
    s.exports = l();
  })(K, function() {
    var c = 1e3, l = 6e4, p = 36e5, v = "millisecond", D = "second", $ = "minute", x = "hour", h = "day", b = "week", m = "month", j = "quarter", _ = "year", Y = "date", z = "Invalid Date", I = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, B = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, C = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(a) {
      var n = ["th", "st", "nd", "rd"], t = a % 100;
      return "[" + a + (n[(t - 20) % 10] || n[t] || n[0]) + "]";
    } }, E = function(a, n, t) {
      var i = String(a);
      return !i || i.length >= n ? a : "" + Array(n + 1 - i.length).join(t) + a;
    }, L = { s: E, z: function(a) {
      var n = -a.utcOffset(), t = Math.abs(n), i = Math.floor(t / 60), e = t % 60;
      return (n <= 0 ? "+" : "-") + E(i, 2, "0") + ":" + E(e, 2, "0");
    }, m: function a(n, t) {
      if (n.date() < t.date())
        return -a(t, n);
      var i = 12 * (t.year() - n.year()) + (t.month() - n.month()), e = n.clone().add(i, m), o = t - e < 0, u = n.clone().add(i + (o ? -1 : 1), m);
      return +(-(i + (t - e) / (o ? e - u : u - e)) || 0);
    }, a: function(a) {
      return a < 0 ? Math.ceil(a) || 0 : Math.floor(a);
    }, p: function(a) {
      return { M: m, y: _, w: b, d: h, D: Y, h: x, m: $, s: D, ms: v, Q: j }[a] || String(a || "").toLowerCase().replace(/s$/, "");
    }, u: function(a) {
      return a === void 0;
    } }, S = "en", T = {};
    T[S] = C;
    var G = "$isDayjsObject", A = function(a) {
      return a instanceof P || !(!a || !a[G]);
    }, V = function a(n, t, i) {
      var e;
      if (!n)
        return S;
      if (typeof n == "string") {
        var o = n.toLowerCase();
        T[o] && (e = o), t && (T[o] = t, e = o);
        var u = n.split("-");
        if (!e && u.length > 1)
          return a(u[0]);
      } else {
        var d = n.name;
        T[d] = n, e = d;
      }
      return !i && e && (S = e), e || !i && S;
    }, M = function(a, n) {
      if (A(a))
        return a.clone();
      var t = typeof n == "object" ? n : {};
      return t.date = a, t.args = arguments, new P(t);
    }, f = L;
    f.l = V, f.i = A, f.w = function(a, n) {
      return M(a, { locale: n.$L, utc: n.$u, x: n.$x, $offset: n.$offset });
    };
    var P = function() {
      function a(t) {
        this.$L = V(t.locale, null, !0), this.parse(t), this.$x = this.$x || t.x || {}, this[G] = !0;
      }
      var n = a.prototype;
      return n.parse = function(t) {
        this.$d = function(i) {
          var e = i.date, o = i.utc;
          if (e === null)
            return /* @__PURE__ */ new Date(NaN);
          if (f.u(e))
            return /* @__PURE__ */ new Date();
          if (e instanceof Date)
            return new Date(e);
          if (typeof e == "string" && !/Z$/i.test(e)) {
            var u = e.match(I);
            if (u) {
              var d = u[2] - 1 || 0, g = (u[7] || "0").substring(0, 3);
              return o ? new Date(Date.UTC(u[1], d, u[3] || 1, u[4] || 0, u[5] || 0, u[6] || 0, g)) : new Date(u[1], d, u[3] || 1, u[4] || 0, u[5] || 0, u[6] || 0, g);
            }
          }
          return new Date(e);
        }(t), this.init();
      }, n.init = function() {
        var t = this.$d;
        this.$y = t.getFullYear(), this.$M = t.getMonth(), this.$D = t.getDate(), this.$W = t.getDay(), this.$H = t.getHours(), this.$m = t.getMinutes(), this.$s = t.getSeconds(), this.$ms = t.getMilliseconds();
      }, n.$utils = function() {
        return f;
      }, n.isValid = function() {
        return this.$d.toString() !== z;
      }, n.isSame = function(t, i) {
        var e = M(t);
        return this.startOf(i) <= e && e <= this.endOf(i);
      }, n.isAfter = function(t, i) {
        return M(t) < this.startOf(i);
      }, n.isBefore = function(t, i) {
        return this.endOf(i) < M(t);
      }, n.$g = function(t, i, e) {
        return f.u(t) ? this[i] : this.set(e, t);
      }, n.unix = function() {
        return Math.floor(this.valueOf() / 1e3);
      }, n.valueOf = function() {
        return this.$d.getTime();
      }, n.startOf = function(t, i) {
        var e = this, o = !!f.u(i) || i, u = f.p(t), d = function(N, O) {
          var H = f.w(e.$u ? Date.UTC(e.$y, O, N) : new Date(e.$y, O, N), e);
          return o ? H : H.endOf(h);
        }, g = function(N, O) {
          return f.w(e.toDate()[N].apply(e.toDate("s"), (o ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice(O)), e);
        }, w = this.$W, y = this.$M, W = this.$D, U = "set" + (this.$u ? "UTC" : "");
        switch (u) {
          case _:
            return o ? d(1, 0) : d(31, 11);
          case m:
            return o ? d(1, y) : d(0, y + 1);
          case b:
            var F = this.$locale().weekStart || 0, R = (w < F ? w + 7 : w) - F;
            return d(o ? W - R : W + (6 - R), y);
          case h:
          case Y:
            return g(U + "Hours", 0);
          case x:
            return g(U + "Minutes", 1);
          case $:
            return g(U + "Seconds", 2);
          case D:
            return g(U + "Milliseconds", 3);
          default:
            return this.clone();
        }
      }, n.endOf = function(t) {
        return this.startOf(t, !1);
      }, n.$set = function(t, i) {
        var e, o = f.p(t), u = "set" + (this.$u ? "UTC" : ""), d = (e = {}, e[h] = u + "Date", e[Y] = u + "Date", e[m] = u + "Month", e[_] = u + "FullYear", e[x] = u + "Hours", e[$] = u + "Minutes", e[D] = u + "Seconds", e[v] = u + "Milliseconds", e)[o], g = o === h ? this.$D + (i - this.$W) : i;
        if (o === m || o === _) {
          var w = this.clone().set(Y, 1);
          w.$d[d](g), w.init(), this.$d = w.set(Y, Math.min(this.$D, w.daysInMonth())).$d;
        } else
          d && this.$d[d](g);
        return this.init(), this;
      }, n.set = function(t, i) {
        return this.clone().$set(t, i);
      }, n.get = function(t) {
        return this[f.p(t)]();
      }, n.add = function(t, i) {
        var e, o = this;
        t = Number(t);
        var u = f.p(i), d = function(y) {
          var W = M(o);
          return f.w(W.date(W.date() + Math.round(y * t)), o);
        };
        if (u === m)
          return this.set(m, this.$M + t);
        if (u === _)
          return this.set(_, this.$y + t);
        if (u === h)
          return d(1);
        if (u === b)
          return d(7);
        var g = (e = {}, e[$] = l, e[x] = p, e[D] = c, e)[u] || 1, w = this.$d.getTime() + t * g;
        return f.w(w, this);
      }, n.subtract = function(t, i) {
        return this.add(-1 * t, i);
      }, n.format = function(t) {
        var i = this, e = this.$locale();
        if (!this.isValid())
          return e.invalidDate || z;
        var o = t || "YYYY-MM-DDTHH:mm:ssZ", u = f.z(this), d = this.$H, g = this.$m, w = this.$M, y = e.weekdays, W = e.months, U = e.meridiem, F = function(O, H, Z, J) {
          return O && (O[H] || O(i, o)) || Z[H].slice(0, J);
        }, R = function(O) {
          return f.s(d % 12 || 12, O, "0");
        }, N = U || function(O, H, Z) {
          var J = O < 12 ? "AM" : "PM";
          return Z ? J.toLowerCase() : J;
        };
        return o.replace(B, function(O, H) {
          return H || function(Z) {
            switch (Z) {
              case "YY":
                return String(i.$y).slice(-2);
              case "YYYY":
                return f.s(i.$y, 4, "0");
              case "M":
                return w + 1;
              case "MM":
                return f.s(w + 1, 2, "0");
              case "MMM":
                return F(e.monthsShort, w, W, 3);
              case "MMMM":
                return F(W, w);
              case "D":
                return i.$D;
              case "DD":
                return f.s(i.$D, 2, "0");
              case "d":
                return String(i.$W);
              case "dd":
                return F(e.weekdaysMin, i.$W, y, 2);
              case "ddd":
                return F(e.weekdaysShort, i.$W, y, 3);
              case "dddd":
                return y[i.$W];
              case "H":
                return String(d);
              case "HH":
                return f.s(d, 2, "0");
              case "h":
                return R(1);
              case "hh":
                return R(2);
              case "a":
                return N(d, g, !0);
              case "A":
                return N(d, g, !1);
              case "m":
                return String(g);
              case "mm":
                return f.s(g, 2, "0");
              case "s":
                return String(i.$s);
              case "ss":
                return f.s(i.$s, 2, "0");
              case "SSS":
                return f.s(i.$ms, 3, "0");
              case "Z":
                return u;
            }
            return null;
          }(O) || u.replace(":", "");
        });
      }, n.utcOffset = function() {
        return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
      }, n.diff = function(t, i, e) {
        var o, u = this, d = f.p(i), g = M(t), w = (g.utcOffset() - this.utcOffset()) * l, y = this - g, W = function() {
          return f.m(u, g);
        };
        switch (d) {
          case _:
            o = W() / 12;
            break;
          case m:
            o = W();
            break;
          case j:
            o = W() / 3;
            break;
          case b:
            o = (y - w) / 6048e5;
            break;
          case h:
            o = (y - w) / 864e5;
            break;
          case x:
            o = y / p;
            break;
          case $:
            o = y / l;
            break;
          case D:
            o = y / c;
            break;
          default:
            o = y;
        }
        return e ? o : f.a(o);
      }, n.daysInMonth = function() {
        return this.endOf(m).$D;
      }, n.$locale = function() {
        return T[this.$L];
      }, n.locale = function(t, i) {
        if (!t)
          return this.$L;
        var e = this.clone(), o = V(t, i, !0);
        return o && (e.$L = o), e;
      }, n.clone = function() {
        return f.w(this.$d, this);
      }, n.toDate = function() {
        return new Date(this.valueOf());
      }, n.toJSON = function() {
        return this.isValid() ? this.toISOString() : null;
      }, n.toISOString = function() {
        return this.$d.toISOString();
      }, n.toString = function() {
        return this.$d.toUTCString();
      }, a;
    }(), tt = P.prototype;
    return M.prototype = tt, [["$ms", v], ["$s", D], ["$m", $], ["$H", x], ["$W", h], ["$M", m], ["$y", _], ["$D", Y]].forEach(function(a) {
      tt[a[1]] = function(n) {
        return this.$g(n, a[0], a[1]);
      };
    }), M.extend = function(a, n) {
      return a.$i || (a(n, P, M), a.$i = !0), M;
    }, M.locale = V, M.isDayjs = A, M.unix = function(a) {
      return M(1e3 * a);
    }, M.en = T[S], M.Ls = T, M.p = {}, M;
  });
})(rt);
var Q = rt.exports;
const k = /* @__PURE__ */ q(Q);
var et = { exports: {} };
(function(s, r) {
  (function(c, l) {
    s.exports = l();
  })(K, function() {
    return function(c, l) {
      var p = l.prototype, v = p.format;
      p.format = function(D) {
        var $ = this, x = this.$locale();
        if (!this.isValid())
          return v.bind(this)(D);
        var h = this.$utils(), b = (D || "YYYY-MM-DDTHH:mm:ssZ").replace(/\[([^\]]+)]|Q|wo|ww|w|WW|W|zzz|z|gggg|GGGG|Do|X|x|k{1,2}|S/g, function(m) {
          switch (m) {
            case "Q":
              return Math.ceil(($.$M + 1) / 3);
            case "Do":
              return x.ordinal($.$D);
            case "gggg":
              return $.weekYear();
            case "GGGG":
              return $.isoWeekYear();
            case "wo":
              return x.ordinal($.week(), "W");
            case "w":
            case "ww":
              return h.s($.week(), m === "w" ? 1 : 2, "0");
            case "W":
            case "WW":
              return h.s($.isoWeek(), m === "W" ? 1 : 2, "0");
            case "k":
            case "kk":
              return h.s(String($.$H === 0 ? 24 : $.$H), m === "k" ? 1 : 2, "0");
            case "X":
              return Math.floor($.$d.getTime() / 1e3);
            case "x":
              return $.$d.getTime();
            case "z":
              return "[" + $.offsetName() + "]";
            case "zzz":
              return "[" + $.offsetName("long") + "]";
            default:
              return m;
          }
        });
        return v.bind(this)(b);
      };
    };
  });
})(et);
var $t = et.exports;
const mt = /* @__PURE__ */ q($t);
Q.extend(mt);
const vt = (s, r = "MM-DD-YYYY") => k(s).format(r);
var nt = { exports: {} };
(function(s, r) {
  (function(c, l) {
    s.exports = l();
  })(K, function() {
    return function(c, l, p) {
      c = c || {};
      var v = l.prototype, D = { future: "in %s", past: "%s ago", s: "a few seconds", m: "a minute", mm: "%d minutes", h: "an hour", hh: "%d hours", d: "a day", dd: "%d days", M: "a month", MM: "%d months", y: "a year", yy: "%d years" };
      function $(h, b, m, j) {
        return v.fromToBase(h, b, m, j);
      }
      p.en.relativeTime = D, v.fromToBase = function(h, b, m, j, _) {
        for (var Y, z, I, B = m.$locale().relativeTime || D, C = c.thresholds || [{ l: "s", r: 44, d: "second" }, { l: "m", r: 89 }, { l: "mm", r: 44, d: "minute" }, { l: "h", r: 89 }, { l: "hh", r: 21, d: "hour" }, { l: "d", r: 35 }, { l: "dd", r: 25, d: "day" }, { l: "M", r: 45 }, { l: "MM", r: 10, d: "month" }, { l: "y", r: 17 }, { l: "yy", d: "year" }], E = C.length, L = 0; L < E; L += 1) {
          var S = C[L];
          S.d && (Y = j ? p(h).diff(m, S.d, !0) : m.diff(h, S.d, !0));
          var T = (c.rounding || Math.round)(Math.abs(Y));
          if (I = Y > 0, T <= S.r || !S.r) {
            T <= 1 && L > 0 && (S = C[L - 1]);
            var G = B[S.l];
            _ && (T = _("" + T)), z = typeof G == "string" ? G.replace("%d", T) : G(T, b, S.l, I);
            break;
          }
        }
        if (b)
          return z;
        var A = I ? B.future : B.past;
        return typeof A == "function" ? A(z) : A.replace("%s", z);
      }, v.to = function(h, b) {
        return $(h, b, this, !0);
      }, v.from = function(h, b) {
        return $(h, b, this);
      };
      var x = function(h) {
        return h.$u ? p.utc() : p();
      };
      v.toNow = function(h) {
        return this.to(x(this), h);
      }, v.fromNow = function(h) {
        return this.from(x(this), h);
      };
    };
  });
})(nt);
var gt = nt.exports;
const st = /* @__PURE__ */ q(gt);
Q.extend(st);
const St = (s) => k().diff(k(s), "hours") >= 24 ? vt(s, "MMMM DD") : k().to(k(s));
Q.extend(st);
const Tt = ({
  date: s,
  dateFrom: r,
  suffix: c = !1
}) => {
  const l = r ? k(r) : k();
  return k(s).from(l, c);
}, Ot = (s, r) => `${s.toString()}-${r.toString().padStart(2, "0")}`, xt = (s, r) => s.length > r ? `${s.slice(0, r)}...` : s, pt = "en-US", Mt = new Intl.PluralRules(pt, { type: "ordinal" }), wt = /* @__PURE__ */ new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"]
]), Wt = (s) => {
  if (s === void 0)
    return "";
  const r = Mt.select(s), c = wt.get(r);
  return `${s.toString()}${c ?? ""}`;
}, _t = (s, r = 1) => {
  const c = 10 ** r;
  return Math.trunc(s * c) / c;
}, Yt = (s = []) => !!s.length && s.every((r) => !!r), it = (s) => /http(?<secured>s)?:\/\//.test(s) ? s : `http://${s}`, Ht = (s) => (r) => !!(r || "").trim() || s, Dt = (s) => s.replace(/^www\./, ""), kt = (s, r, c) => {
  if (!(r != null && r.length))
    return !1;
  try {
    const l = new URL(it(s)), p = c != null && c.wwwMatching ? Dt(l.hostname) : l.hostname;
    return r.includes(p);
  } catch {
    return !1;
  }
}, zt = (s) => /^(?<protocol>https?:\/\/)?(?<subdomnain>.*\.)?linkedin\.com\/(?<path>pub|in|profile)\/.*$/.test(
  s
), yt = /^(?<protocol>https?:\/\/)?(?<subdomain>www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]?/, Lt = (s) => yt.test(it(s)), At = (s, r) => (c) => parseFloat(c) < s ? r : !0;
export {
  bt as colorAndBackground,
  xt as cutText,
  Yt as everyItemTrue,
  vt as formatDate,
  St as formatDateBasedOnHoursDiff,
  Wt as formatOrdinals,
  Tt as formatTimeFrom,
  it as getUrlWithProtocol,
  dt as hex2rgba,
  Ot as normalizeDate,
  Ht as requiredWithTrim,
  _t as truncateValue,
  kt as urlHostnameMatchHostnameList,
  zt as validLinkedinUrl,
  Lt as validUrl,
  At as valueGreaterOrEqualThanXOrError
};
