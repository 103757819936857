import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button } from '@boopos/design-system';

import { AccordionCategories } from 'components/AccordionCategories';

import { useUser } from 'providers/UserProvider';

import { AskPriceFilter } from './AskPriceFilter';
import { BrokerFilter } from './BrokerFilter';
import {
  IForSaleFilters,
  IForSaleFiltersForm,
} from './ForSaleFilters.interface';
import {
  ButtonTotalNumber,
  Footer,
  StyledForm,
  Styles,
  Wrapper,
} from './ForSaleSidebarFilters.styles';
import { HiddenCurrency } from './HiddenCurrency';
import { InfoTooltip, InfoTooltipController } from './InfoTooltip';
import { LendingAvailableFilter } from './LendingAvailableFilter';
import { ProfitMultiplierRangeFilter } from './ProfitMultiplierRangeFilter';
import { TTMProfitFilter } from './TTMProfitFilter';
import { TTMRevenueFilter } from './TTMRevenueFilter';
import { useForSaleInitialValues } from './useForSaleInitialValues';
import { useForSaleTotals } from './useForSaleTotals';

export const ForSaleSidebarFilters = ({
  onSubmit,
  onReset,
  defaultValues,
}: IForSaleFilters) => {
  const initialValues = useForSaleInitialValues();
  const { user } = useUser();
  const { t } = useTranslation();

  const formMethods = useForm<IForSaleFiltersForm>({
    defaultValues,
  });

  const handleInternalSubmit = useCallback(
    (data: IForSaleFiltersForm) => {
      onSubmit(data);
    },
    [onSubmit]
  );

  const { handleSubmit, watch, reset } = formMethods;

  const { total } = useForSaleTotals(watch);

  const handleReset = useCallback(() => {
    reset(initialValues);
    onReset();
  }, [onReset, reset, initialValues]);

  return (
    <>
      <FormProvider {...formMethods}>
        <StyledForm onSubmit={handleSubmit(handleInternalSubmit)}>
          <HiddenCurrency />
          <Wrapper>
            <Styles.FilterLabel>
              {t('ForSaleFilters:ask_price_label')}
            </Styles.FilterLabel>
            <AskPriceFilter />
            <Styles.FilterLabel>
              {t('ForSaleFilters:multiplier_label')}
              <InfoTooltip content={t('ForSaleFilters:multiple_tooltip')} />
            </Styles.FilterLabel>
            <ProfitMultiplierRangeFilter />
            <Styles.FilterLabel>
              {t('ForSaleFilters:ttm_profit_label')}
              <InfoTooltip content={t('ForSaleFilters:profit_tooltip')} />
            </Styles.FilterLabel>
            <TTMProfitFilter />
            <Styles.FilterLabel>
              {t('ForSaleFilters:ttm_revenue_label')}
              <InfoTooltip content={t('ForSaleFilters:revenue_tooltip')} />
            </Styles.FilterLabel>
            <TTMRevenueFilter />
            <LendingAvailableFilter />

            <Styles.FilterLabel>
              {t('ForSaleFilters:type_categories_label')}
            </Styles.FilterLabel>
            <Box css={{ mb: '$6' }}>
              <AccordionCategories defaultExpanded={false} />
            </Box>
            {user?.qualified && (
              <>
                <Styles.FilterLabel>
                  {t('ForSaleFilters:deal_source_label')}
                </Styles.FilterLabel>
                <BrokerFilter />
              </>
            )}
          </Wrapper>
          <Footer>
            <Button
              fullWidth
              type="submit"
              data-testid="apply-filters-button"
              css={{ mb: '.8rem' }}
            >
              {t('ForSaleFilters:show_listings')}{' '}
              <ButtonTotalNumber>
                ({typeof total !== 'undefined' ? total : '-'})
              </ButtonTotalNumber>
            </Button>
            <Button
              onClick={handleReset}
              variant="tertiary"
              fullWidth
              type="button"
              data-testid="reset-filters-button"
            >
              {t('ForSaleFilters:reset_filters')}
            </Button>
          </Footer>
        </StyledForm>
      </FormProvider>
      <InfoTooltipController />
    </>
  );
};
