import { AnimatePresence } from 'framer-motion';
import { t } from 'i18next';
import { useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';

import { ButtonFeedback } from '@boopos/design-system';

import { InlineMessage } from 'ui/InlineMessage';

import {
  ActionSideModal,
  ActionSideModalCloseHeader,
  ActionSideModalCommonStyles,
  ActionSideModalLoading,
  bottomNavAnimationVariants,
  useNavigateFromActionSideModalWithParams,
} from 'components/ActionSideModal';
import { ApplicationDealStructureForm } from 'components/PortfolioApplication/PortfolioApplicationDealStructure/ApplicationDealStructureForm';
import { DealStructureFormValues } from 'components/PortfolioApplication/PortfolioApplicationDealStructure/ApplicationDealStructureForm.interface';
import {
  parseFromApi,
  parseToApi,
} from 'components/PortfolioApplication/PortfolioApplicationDealStructure/ApplicationDealStructureForm.utills';

import { apiV2 } from 'lib/api';
import { useProvideFinancing } from 'lib/loans/useProvideFinancing';
import { useSellApplicationFromUrlParams } from 'lib/sell-application/useSellApplicationFromUrlParams';

interface IOutletContext {
  handleRefreshLoan: () => Promise<void>;
  loanId?: string;
}

export const ApplicationDealStructureModal = ({
  basePath,
}: {
  basePath: string;
}) => {
  const outletContext = useOutletContext<IOutletContext>() || {};

  const { applicationId, sellApplication, mutate } =
    useSellApplicationFromUrlParams({
      loanId: outletContext?.loanId,
    });

  const { handleRefreshLoan } = outletContext;

  const { onClose } = useNavigateFromActionSideModalWithParams({ basePath });

  const onSubmit = useCallback(
    async (data: DealStructureFormValues) => {
      if (!applicationId) return;
      await apiV2.updateApplication(applicationId, {
        application: parseToApi(data),
      });
      await handleRefreshLoan?.();
      await mutate();
      onClose();
    },
    [mutate, handleRefreshLoan, onClose, applicationId]
  );

  const provideFinancing = useProvideFinancing(sellApplication);

  return (
    <ActionSideModal
      header={<ActionSideModalCloseHeader onClose={onClose} />}
      onClose={onClose}
    >
      <ActionSideModalCommonStyles.Title data-testid="deal-structure-title">
        {t('PortfolioApplicationDealStructure:modal_title')}
      </ActionSideModalCommonStyles.Title>

      <ActionSideModalCommonStyles.Subtitle mbSmall>
        {t('PortfolioApplicationDealStructure:modal_description')}
      </ActionSideModalCommonStyles.Subtitle>

      {!sellApplication ? (
        <ActionSideModalLoading />
      ) : (
        <>
          {provideFinancing && (
            <InlineMessage
              heading={t('PortfolioApplicationDealStructure:finance_message')}
              css={{ mb: '2.4rem' }}
              type="actionRequired"
            />
          )}
          <ApplicationDealStructureForm
            as={ActionSideModalCommonStyles.Form}
            defaultValues={parseFromApi(sellApplication?.application)}
            onSubmit={onSubmit}
            actionsComponent={({ isLoading, isSuccess }) => (
              <AnimatePresence>
                <ActionSideModalCommonStyles.BottomNav
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={bottomNavAnimationVariants}
                >
                  <ActionSideModalCommonStyles.BottomNavLeftText />
                  <ButtonFeedback
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    data-testid="save-button"
                  >
                    {t('PortfolioApplicationDealStructure:modal_save')}
                  </ButtonFeedback>
                </ActionSideModalCommonStyles.BottomNav>
              </AnimatePresence>
            )}
          />
        </>
      )}
    </ActionSideModal>
  );
};
