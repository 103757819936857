import { forwardRef } from 'react';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContentCellProps } from './ContentCell.interface';
import { Styles } from './ContentCell.styles';

export const ContentCellChild = forwardRef<HTMLDivElement, ContentCellProps>(
  (
    {
      children,
      title,
      action,
      variant = 'idle',
      customIcon,
      cssTitle,
      showIcon = true,
      ...props
    },
    ref
  ) => {
    const Icon = customIcon ? (
      <Styles.CustomIcon>{customIcon}</Styles.CustomIcon>
    ) : (
      <Styles.Icon variant={variant}>
        {variant === 'idle' && (
          <FontAwesomeIcon
            icon={regular('circle')}
            size="xl"
            data-testid="idle-icon"
          />
        )}
        {variant === 'success' && (
          <FontAwesomeIcon
            icon={solid('circle-check')}
            size="xl"
            data-testid="success-icon"
          />
        )}
        {variant === 'active' && (
          <FontAwesomeIcon
            icon={solid('circle-arrow-right')}
            size="xl"
            data-testid="active-icon"
          />
        )}
        {variant === 'warning' && (
          <FontAwesomeIcon
            icon={solid('circle-exclamation')}
            size="xl"
            data-testid="warning-icon"
          />
        )}
        {variant === 'error' && (
          <FontAwesomeIcon
            icon={solid('circle-exclamation')}
            size="xl"
            data-testid="error-icon"
          />
        )}
      </Styles.Icon>
    );

    return (
      <Styles.Wrapper {...props} variant={variant} ref={ref}>
        <Styles.Header>
          {showIcon && Icon}
          <Styles.Title css={cssTitle}>{title}</Styles.Title>
          {!!action && <Styles.Action>{action}</Styles.Action>}
        </Styles.Header>
        {!!children && (
          <Styles.Content padded={showIcon} hasErrors={variant === 'error'}>
            {children}
          </Styles.Content>
        )}
      </Styles.Wrapper>
    );
  }
);

export const ContentCell = Object.assign(ContentCellChild, {
  Parent: Styles.Parent,
  Title: Styles.Title,
  Wrapper: Styles.Wrapper,
  Header: Styles.Header,
  Divider: Styles.Divider,
  HintMessage: Styles.HintMessage,
  LightContent: Styles.LightContent,
});
