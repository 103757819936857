import { IApiFile, IUser } from 'lib/types';

import { IProfileForm } from './ProfileForm.interface';

export const parseFromApi = (user?: IUser) => ({
  firstName: user?.firstName,
  lastName: user?.lastName,
  marketingOptIn: user?.marketingOptIn,
  linkedinUrl: user?.linkedinUrl,
  cvFiles: user?.cv?.files,
});

export const parseToApi = (data: IProfileForm) => {
  const cvFiles = data?.cvFiles;
  const hasCvFiles = !!cvFiles && cvFiles?.length > 0;
  const linkedinUrl = hasCvFiles ? '' : data.linkedinUrl?.trim();

  return {
    firstName: data?.firstName,
    lastName: data?.lastName,
    marketingOptIn: data?.marketingOptIn,
    linkedinUrl,
    cv: hasCvFiles ? { files: data.cvFiles } : { files: [] as IApiFile[] },
  };
};
