import * as Stitches from '@stitches/react';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { Button } from '@boopos/design-system';

import { BannerStyles } from './Banner.styles';

export const BannerPrimaryAction: FC<PropsWithChildren<any>> = ({
  children,
  ...props
}) => (
  <Button variant="primary" {...props}>
    {children}
  </Button>
);

export const BannerSecondaryAction: FC<PropsWithChildren<any>> = ({
  children,
  ...props
}) => (
  <Button variant="secondary" {...props}>
    {children}
  </Button>
);

export interface BannerProps
  extends Stitches.VariantProps<typeof BannerStyles.Wrapper> {
  icon?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  css?: Stitches.CSS;
}

export const Banner = ({
  icon,
  title,
  description,
  children,
  css,
  ...props
}: BannerProps) => {
  return (
    <BannerStyles.Wrapper css={css} {...props}>
      {!!icon && <BannerStyles.Icon>{icon}</BannerStyles.Icon>}
      <BannerStyles.Container>
        {(!!description || !!title) && (
          <BannerStyles.Content>
            {!!title && <BannerStyles.Title>{title}</BannerStyles.Title>}
            {description}
          </BannerStyles.Content>
        )}
        <BannerStyles.Actions>{children}</BannerStyles.Actions>
      </BannerStyles.Container>
    </BannerStyles.Wrapper>
  );
};
