import { useTranslation } from 'react-i18next';

import { styled } from '@boopos/design-system';

import { ContentCell } from 'ui/ContentCell';

import { formatMoney } from 'lib/format';
import { FinancingRange } from 'lib/types';

const Styles = {
  Range: styled('div', {
    fontWeight: '$bold',
    fontSize: '2rem',
    color: '$textPrimary',
    lineHeight: '2.4rem',
  }),
};

export const FinancingRangeSummary = ({
  financingRange,
}: {
  financingRange?: FinancingRange;
}) => {
  const { t } = useTranslation();

  if (!financingRange) {
    return null;
  }

  const { min, max } = financingRange;

  return (
    <ContentCell.Parent css={{ mb: '$4' }}>
      <ContentCell.Wrapper>
        <ContentCell.Header>
          <ContentCell.Title>
            {t('FinancingRangeSummary:title')}
          </ContentCell.Title>
        </ContentCell.Header>
        <ContentCell.LightContent>
          {t('FinancingRangeSummary:description')}
        </ContentCell.LightContent>
        <Styles.Range>
          {t('FinancingRangeSummary:range', {
            min: formatMoney(min),
            max: formatMoney(max),
          })}
        </Styles.Range>
      </ContentCell.Wrapper>
    </ContentCell.Parent>
  );
};
