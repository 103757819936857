import { BusinessTypesEnum, ForSaleApplicationApi } from 'lib/types';

export const useProvideFinancing = (
  sellApplication?: ForSaleApplicationApi
) => {
  return (
    sellApplication?.business?.typeLiteral?.name ===
    BusinessTypesEnum.subscriptionBased
  );
};
