import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';

import {
  QualificationStatusApi,
  useQualificationStatus,
} from 'components/QualificationStatus';
import { useSubmitForEvaluationError } from 'components/SubmitForEvaluation/useSubmitForEvaluationError';

import api from 'lib/api';
import {
  ILoanApi,
  LoanDocumentType,
  SubmitForEvaluationErrorCodes,
  SubmitForEvaluationErrorResponse,
} from 'lib/types';

import { useSubmitActionStatus } from './SubmitForEvaluation.utils';

export const useSubmitForEvaluationValidation = (loan?: ILoanApi) => {
  /**
   * Disclaimer: This is a temporary solution to handle the validation of the deal structure.
   * It should be removed once the deal structure is validated in the backend.
   *
   * This is simulating the backend response when errors, so we just need to remove this hook wrapper
   * and the handleValidation call as soon as the validations are done in the backend.
   *
   * In order for this to work we should maintain the same structure as of:
   * @boopos-app/apps/api/src/business-owners-bff/portfolio/ApplicationsController.ts#156
   */

  const { status } = useQualificationStatus();

  const handleValidation = useCallback(
    (errors: SubmitForEvaluationErrorCodes[]) => {
      if (!errors?.length) {
        return;
      }

      const error = new AxiosError<SubmitForEvaluationErrorResponse>();

      error.response = {
        data: {
          errors: errors.map((err) => ({
            code: err,
          })),
        },
      } as any;

      throw error;
    },
    []
  );

  const validate = useCallback(() => {
    const errors = [];

    if (status !== QualificationStatusApi.QUALIFIED) {
      errors.push(SubmitForEvaluationErrorCodes.userNotQualified);
    }

    if (loan) {
      if (!loan.purchaseDealStructure) {
        errors.push(SubmitForEvaluationErrorCodes.askingPrice);
      }

      if (loan.pendingDocuments?.includes(LoanDocumentType.profitAndLoss)) {
        errors.push(SubmitForEvaluationErrorCodes.missingPnL);
      }

      if (loan.pendingDocuments?.includes(LoanDocumentType.cohorts)) {
        errors.push(SubmitForEvaluationErrorCodes.missingCohorts);
      }
    }

    handleValidation(errors);
  }, [loan, handleValidation, status]);

  return { validate };
};

export const useSubmitForEvaluation = ({
  loan,
  onSuccess,
}: {
  loan: ILoanApi;
  onSuccess: (cb?: () => void) => Promise<void>;
}) => {
  const loanId = loan?.id;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { validate } = useSubmitForEvaluationValidation(loan);
  const { apiHandler: errorHandler } = useSubmitForEvaluationError();

  const { disabled, isResubmit } = useSubmitActionStatus(loan);

  const evaluate = useCallback(async () => {
    try {
      validate();

      setLoading(true);

      await api.sendDealToEvaluation(loanId);

      await onSuccess(() => {
        setLoading(false);
        setSuccess(true);
      });
    } catch (err) {
      setLoading(false);
      const error = err as AxiosError<SubmitForEvaluationErrorResponse>;

      errorHandler(error?.response?.data);
    }
  }, [loanId, onSuccess, validate, errorHandler]);

  return {
    evaluate,
    isResubmit,
    loading,
    success,
    disabled,
  };
};
