import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FilterLoading } from 'components/ForSaleFilters/FilterLoading';
import { useForSaleFiltersConfig } from 'components/ForSaleFilters/useForSaleFiltersConfig';

import {
  BrokerSelector,
  OtherBrokers,
  ProprietaryBroker,
} from './BrokerSelector';
import { Styles } from './BrokerSelector.styles';

export const BrokerFilter = () => {
  const { register } = useFormContext();
  const { t } = useTranslation();
  const filtersConfig = useForSaleFiltersConfig();

  const { brokers } = filtersConfig ?? {};
  const { brokerList, availableBrokers } = brokers ?? {};

  if (!brokerList?.length) {
    return <FilterLoading />;
  }

  return (
    <>
      <Styles.GridWrapper>
        <ProprietaryBroker />
        {brokerList?.map((broker) => (
          <BrokerSelector key={broker.slug} broker={broker} />
        ))}
        <OtherBrokers>{t('ForSaleFilters:other_brokers_label')}</OtherBrokers>
      </Styles.GridWrapper>
      <input
        {...register('availableBrokers')}
        type="hidden"
        value={availableBrokers}
      />
    </>
  );
};
