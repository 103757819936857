import { ComponentProps, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@boopos/design-system';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { DealDetail } from 'components/DealDetail';
import { shouldShowCompleteClosingInformation } from 'components/DealTimeline/CompleteClosingInformation';
import { TermSheetHandler } from 'components/TermSheetHandler';

import { useMeetingUrls } from 'lib/meetings';
import { ILoanApi, LoanStatus } from 'lib/types';

interface Props extends ComponentProps<typeof DealDetail.SideWrapper> {
  loan?: ILoanApi;
  showInDesktop?: boolean;
}

export const WhatsNext = ({ loan }: Props) => {
  const { buyerMeetingUrl } = useMeetingUrls();
  const { t } = useTranslation();

  if (!loan) {
    return null;
  }

  if (loan?.status === LoanStatus.contract_sent) {
    return <>{t('TimelineWhatsNext:status_contract_sent')}</>;
  }

  if (loan?.status === LoanStatus.canceled) {
    return <>{t('TimelineWhatsNext:status_canceled')}</>;
  }

  if (shouldShowCompleteClosingInformation(loan)) {
    return <>{t('TimelineWhatsNext:complete_closing_information')}</>;
  }

  if (loan?.status === LoanStatus.termsheet_sent) {
    return (
      <TermSheetHandler termSheet={loan?.termSheet}>
        <TermSheetHandler.Base>
          {t('TimelineWhatsNext:status_termsheet_sent')}
        </TermSheetHandler.Base>
        <TermSheetHandler.External>
          {t('TimelineWhatsNext:status_termsheet_sent_external')}
        </TermSheetHandler.External>
      </TermSheetHandler>
    );
  }

  if (
    loan?.status === LoanStatus.analysis ||
    loan?.status === LoanStatus.applied
  ) {
    return <>{t('TimelineWhatsNext:status_analysis')}</>;
  }

  if (loan?.status === LoanStatus.missing_info) {
    return (
      <>
        <DealDetail.SideTextContent>
          <div>{t('TimelineWhatsNext:missing_info_first')}</div>
          <div>{t('TimelineWhatsNext:missing_info_second')}</div>
        </DealDetail.SideTextContent>
        <div>
          <Button
            as="a"
            variant="secondary"
            href={buyerMeetingUrl}
            leftIcon={<FontAwesomeIcon icon={regular('comments')} />}
            target="_blank"
          >
            {t('TimelineWhatsNext:advisor_chat')}
          </Button>
        </div>
      </>
    );
  }

  return null;
};

export const TimelineWhatsNext: FC<Props> = ({
  loan,
  showInDesktop = false,
  ...props
}) => {
  const { t } = useTranslation();

  if (!loan || loan?.status === LoanStatus.contract_signed) {
    return null;
  }

  return (
    <DealDetail.SideWrapper showInDesktop={showInDesktop} {...props}>
      <DealDetail.SideTitle data-testid="whats-next-title">
        {t('TimelineWhatsNext:title')}
      </DealDetail.SideTitle>

      <WhatsNext loan={loan} />
    </DealDetail.SideWrapper>
  );
};
