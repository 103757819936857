import { useTranslation } from 'react-i18next';

import { Box } from '@boopos/design-system';

import { QualificationBanner } from 'components/QualificationBanner';
import { useSubmitForEvaluationError } from 'components/SubmitForEvaluation/useSubmitForEvaluationError';

export const TargetBusinessQualificationBanner = () => {
  const { t } = useTranslation();
  const { qualification: qualificationError } = useSubmitForEvaluationError();

  return (
    <Box css={{ mb: '$6' }}>
      <QualificationBanner
        hasError={!!qualificationError}
        customLiterals={{
          emptyDescription: (
            <strong>
              {t('TargetBusinessQualificationBanner:empty_title')}
            </strong>
          ),
          underReviewDescription: (
            <>
              {t('TargetBusinessQualificationBanner:under_review_description')}
            </>
          ),
        }}
      />
      {!!qualificationError && (
        <Box css={{ color: '$textStateNegative' }}>
          {t('TargetBusinessQualificationBanner:error_description')}
        </Box>
      )}
    </Box>
  );
};
