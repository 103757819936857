import React from 'react';
import { useTranslation } from 'react-i18next';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ListingDetailStyles } from 'components/ListingDetail/ListingDetail.styles';
import { ListingInfoContent } from 'components/ListingDetail/ListingInfoContent';
import { ListingInfoItem } from 'components/ListingDetail/ListingInfoItem';

import { Styles } from './ListingUnlockedBusinessDetails.styles';

export const ListingUnlockedOwnerDetails = ({
  ownerEmail,
  ownerName,
}: {
  ownerName?: string;
  ownerEmail?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Styles.Wrapper>
      <ListingInfoContent
        icon={solid('user')}
        title={t('ListingDetail:owner_details_title')}
      >
        <ListingDetailStyles.InfoGrid>
          {!!ownerName && (
            <ListingInfoItem title={t('ListingDetail:owner_details_name')}>
              {ownerName}
            </ListingInfoItem>
          )}

          {!!ownerEmail && (
            <ListingInfoItem title={t('ListingDetail:owner_details_email')}>
              <a href={`mailto:${ownerEmail}`} target="_blank" rel="noreferrer">
                {ownerEmail}
              </a>
            </ListingInfoItem>
          )}
        </ListingDetailStyles.InfoGrid>
      </ListingInfoContent>
    </Styles.Wrapper>
  );
};
