import { useTranslation } from 'react-i18next';

import { ContentCell } from 'ui/ContentCell';
import { ContentTable } from 'ui/ContentTable';

import { FormatUrl } from 'components/FormatUrl';

import { otherCategory } from 'lib/businessType';
import { formatMoney } from 'lib/format';
import { MoneyFormat } from 'lib/format/money/formatMoney.interface';
import { BuyApplication, IBusinessApi } from 'lib/types';

export interface TargetBusinessDetailsProps {
  business: IBusinessApi;
  application?: BuyApplication;
}

export const TargetBusinessDetails = ({
  business,
  application,
}: TargetBusinessDetailsProps) => {
  const { t } = useTranslation();

  if (!business) return null;

  return (
    <ContentCell
      title={t('TargetBusinessDetails:title')}
      variant="success"
      active={false}
      showIcon={false}
    >
      <ContentTable.Wrapper>
        <ContentTable.Label>
          {t('TargetBusinessDetails:business_name')}
        </ContentTable.Label>
        <ContentTable.Value>{business.name}</ContentTable.Value>
        <ContentTable.Label>
          {t('TargetBusinessDetails:business_website')}
        </ContentTable.Label>
        <ContentTable.Value noWrap>
          <FormatUrl url={business.url} target="_blank" />
        </ContentTable.Value>
        {!!business.businessTypeLiteral && (
          <>
            <ContentTable.Label>
              {t('TargetBusinessDetails:business_type')}
            </ContentTable.Label>
            <ContentTable.Value>
              {business.businessTypeLiteral.id === otherCategory
                ? business.businessTypeOther
                : business.businessTypeLiteral.name}
            </ContentTable.Value>
          </>
        )}
        {!!business?.age && (
          <>
            <ContentTable.Label>
              {t('TargetBusinessDetails:business_age')}
            </ContentTable.Label>
            <ContentTable.Value>
              {t('TargetBusinessDetails:business_age_years', {
                count: parseInt(business.age as string, 10),
              })}
            </ContentTable.Value>
          </>
        )}
        {!!business?.brokerLongName && (
          <>
            <ContentTable.Label>
              {t('TargetBusinessDetails:broker')}
            </ContentTable.Label>
            <ContentTable.Value>{business.brokerLongName}</ContentTable.Value>
          </>
        )}
        {!!application?.ttmRevenue && (
          <>
            <ContentTable.Label>
              {t('TargetBusinessDetails:ttm_revenue')}
            </ContentTable.Label>
            <ContentTable.Value>
              {formatMoney(application.ttmRevenue, {
                format: MoneyFormat.long,
              })}
            </ContentTable.Value>
          </>
        )}
        {!!application?.ttmProfit && (
          <>
            <ContentTable.Label>
              {t('TargetBusinessDetails:ttm_profit')}
            </ContentTable.Label>
            <ContentTable.Value>
              {formatMoney(application.ttmProfit, {
                format: MoneyFormat.long,
              })}
            </ContentTable.Value>
          </>
        )}
      </ContentTable.Wrapper>
    </ContentCell>
  );
};
