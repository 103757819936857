import { ReactNode, useId } from 'react';
import { useFormContext } from 'react-hook-form';

import { BrokerImage } from 'components/BrokerImages';

import { IBroker } from 'lib/types';

import { Styles } from './BrokerSelector.styles';

export const BrokerSelector = ({ broker }: { broker: IBroker }) => {
  const id = useId();
  const { register } = useFormContext();

  return (
    <Styles.LabelWrapper>
      <Styles.Input
        {...register('brokers[]')}
        data-testid={`broker-${broker.slug}`}
        type="checkbox"
        value={broker.slug}
        id={id}
      />
      <Styles.Label htmlFor={id}>
        <BrokerImage broker={broker} />
      </Styles.Label>
    </Styles.LabelWrapper>
  );
};

export const ProprietaryBroker = () => {
  const { register } = useFormContext();

  return (
    <Styles.LabelWrapper>
      <Styles.Input
        {...register('isProprietary')}
        data-testid="propietary-selector"
        id="propietary-selector"
        type="checkbox"
      />
      <Styles.Label htmlFor="propietary-selector">
        <BrokerImage broker={{ slug: 'boopos', name: 'Boopos' }} />
      </Styles.Label>
    </Styles.LabelWrapper>
  );
};

export const OtherBrokers = ({ children }: { children: ReactNode }) => {
  const { register } = useFormContext();

  return (
    <Styles.LabelWrapper>
      <Styles.Input
        {...register('otherBrokers')}
        data-testid="other-broker-selector"
        id="other-brokers"
        type="checkbox"
      />
      <Styles.Label htmlFor="other-brokers">{children}</Styles.Label>
    </Styles.LabelWrapper>
  );
};
