import { atom, useAtom } from 'jotai';
import { ReactNode, useCallback, useEffect } from 'react';

import {
  SubmitForEvaluationErrorCodes,
  SubmitForEvaluationErrorResponse,
} from 'lib/types';

enum SubmitForEvaluationError {
  DEAL_STRUCTURE = 'deal_structure',
  BUSINESS_PERFORMANCE = 'business_performance',
  PROFIT_AND_LOSS = 'profit_and_loss',
  COHORTS = 'cohorts',
  NOT_QUALIFIED = 'not_qualified',
}

const submitForEvaluationErrorsAtom = atom<SubmitForEvaluationError[]>([]);

const ErrorsMap = {
  [SubmitForEvaluationErrorCodes.askingPrice]:
    SubmitForEvaluationError.DEAL_STRUCTURE,
  [SubmitForEvaluationErrorCodes.businessPerformance]:
    SubmitForEvaluationError.BUSINESS_PERFORMANCE,
  [SubmitForEvaluationErrorCodes.missingPnL]:
    SubmitForEvaluationError.PROFIT_AND_LOSS,
  [SubmitForEvaluationErrorCodes.missingCohorts]:
    SubmitForEvaluationError.COHORTS,
  [SubmitForEvaluationErrorCodes.userNotQualified]:
    SubmitForEvaluationError.NOT_QUALIFIED,
};

const parseFromApi = (response?: SubmitForEvaluationErrorResponse) =>
  response?.errors?.map((error) => ErrorsMap[error?.code]).filter(Boolean);

export const useSubmitForEvaluationError = () => {
  const [errors, setErrors] = useAtom(submitForEvaluationErrorsAtom);

  const apiHandler = useCallback(
    (response?: SubmitForEvaluationErrorResponse) => {
      const apiErrors = parseFromApi(response);

      setErrors(apiErrors ?? []);
    },
    [setErrors]
  );

  return {
    dealStructure: errors.includes(SubmitForEvaluationError.DEAL_STRUCTURE),
    businessPerformance: errors.includes(
      SubmitForEvaluationError.BUSINESS_PERFORMANCE
    ),
    profitAndLoss: errors.includes(SubmitForEvaluationError.PROFIT_AND_LOSS),
    cohorts: errors.includes(SubmitForEvaluationError.COHORTS),
    qualification: errors.includes(SubmitForEvaluationError.NOT_QUALIFIED),
    apiHandler,
  };
};

export const SubmitForEvaluationErrorContext = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [, setErrors] = useAtom(submitForEvaluationErrorsAtom);

  useEffect(() => {
    return () => {
      setErrors([]);
    };
  }, [setErrors]);

  return <>{children}</>;
};
