import React from 'react';
import { useTranslation } from 'react-i18next';

import { DealDetail } from 'components/DealDetail';
import { SellerTypeRender } from 'components/ListingDetail/SellerTypeRender';

import { ListingForSale } from 'lib/types';

import { WhatsNextBroker } from './WhatsNextBroker';
import { WhatsNextProprietary } from './WhatsNextProprietary';

export const WhatsNext = ({ listing }: { listing?: ListingForSale }) => {
  const { t } = useTranslation();

  if (!listing) {
    return null;
  }

  return (
    <>
      <SellerTypeRender.Proprietary sellerType={listing.sellerType}>
        <WhatsNextProprietary listing={listing}>
          {(children) => (
            <DealDetail.SideSoftWrapper>
              <DealDetail.SideTitle data-testid="whats-next-title">
                {t('ForSaleWhatsNext:title')}
              </DealDetail.SideTitle>
              <DealDetail.SideTextContent>
                {children}
              </DealDetail.SideTextContent>
            </DealDetail.SideSoftWrapper>
          )}
        </WhatsNextProprietary>
      </SellerTypeRender.Proprietary>
      <SellerTypeRender.Broker sellerType={listing.sellerType}>
        <WhatsNextBroker listing={listing}>
          {(children) => (
            <DealDetail.SideSoftWrapper>
              <DealDetail.SideTitle data-testid="whats-next-title">
                {t('ForSaleWhatsNext:title')}
              </DealDetail.SideTitle>
              <DealDetail.SideTextContent>
                {children}
              </DealDetail.SideTextContent>
            </DealDetail.SideSoftWrapper>
          )}
        </WhatsNextBroker>
      </SellerTypeRender.Broker>
    </>
  );
};
