import React, { FC } from 'react';

import { BasicInformationDetails } from 'components/BasicInformationDetails';
import { BuyerInsightsTimelinePreview } from 'components/BuyerInsights/BuyerInsightsTimelinePreview';
import { ClosingChecklistDetails } from 'components/DealTimeline/ClosingChecklistDetails';
import { TermSheetDetails } from 'components/DealTimeline/TermSheetDetails';
import { TargetBusinessDetails } from 'components/TargetBusinessDetails';

import { ActivityName, Currency, ILoanApi, LoanStatus } from 'lib/types';

import { routePaths } from 'router/routePaths';

import { FinancingRangeSummary } from 'components/FinancingRangeSummary/FinancingRangeSummary';
import { AcceptedLoanTermsActivity } from './AcceptedLoanTermsActivity';
import { CompletedClosingInformationActivity } from './CompletedClosingInformationActivity';
import { DealSubmittedActivity } from './DealSubmittedActivity';
import { DealUnlockedActivity } from './DealUnlockedActivity';
import { ListingUnlockedActivity } from './ListingUnlockedActivity';
import { SellerContactedActivity } from './SellerContactedActivity';
import { TargetBusinessAddedActivity } from './TargetBusinessAddedActivity';

interface ModuleProps {
  date: string;
  loan: ILoanApi;
  isLast?: boolean;
}

export const ModuleByActivityName: {
  [key in ActivityName]?: FC<ModuleProps>;
} = {
  [ActivityName.targetBusinessAdded]: ({
    date,
    loan,
    ...rest
  }: ModuleProps) => (
    <TargetBusinessAddedActivity date={date} {...rest}>
      <FinancingRangeSummary
        financingRange={{
          min: { amount: 100000, currency: Currency.USD },
          max: { amount: 1000000, currency: Currency.USD },
        }}
      />
      <TargetBusinessDetails
        business={loan.business}
        application={loan.application}
      />
    </TargetBusinessAddedActivity>
  ),
  [ActivityName.sendForEvaluation]: ({ date, loan, ...rest }: ModuleProps) => (
    <DealSubmittedActivity date={date} {...rest}>
      {loan.status !== LoanStatus.missing_info && (
        <BasicInformationDetails loan={loan} editable={false} />
      )}
    </DealSubmittedActivity>
  ),
  [ActivityName.termSheetSigned]: ({ date, loan, ...rest }: ModuleProps) => (
    <AcceptedLoanTermsActivity date={date} loan={loan} {...rest}>
      <TermSheetDetails
        termSheet={loan.termSheet}
        id={loan.id}
        title={loan.title}
      />
      <BuyerInsightsTimelinePreview
        businessInsights={loan?.businessInsights}
        businessId={loan?.businessId}
        insightsPath={routePaths.dealFlowInsights}
      />
    </AcceptedLoanTermsActivity>
  ),
  [ActivityName.closingInformationCompleted]: ({
    date,
    loan,
    ...rest
  }: ModuleProps) => (
    <CompletedClosingInformationActivity date={date} {...rest}>
      {loan.status !== LoanStatus.due_diligence_missing_info && (
        <ClosingChecklistDetails loan={loan} editable={false} />
      )}
    </CompletedClosingInformationActivity>
  ),
  [ActivityName.sellerContacted]: ({ date, ...rest }: ModuleProps) => (
    <SellerContactedActivity date={date} {...rest} />
  ),
  [ActivityName.dealUnlocked]: ({ date, loan, ...rest }: ModuleProps) => (
    <DealUnlockedActivity date={date} {...rest}>
      <ListingUnlockedActivity listingId={loan?.listing?.id} />
    </DealUnlockedActivity>
  ),
};
