import { useTranslation } from 'react-i18next';

import { ButtonFeedback } from '@boopos/design-system';

import { ILoanApi } from 'lib/types';

import { useSubmitForEvaluation } from './SubmitForEvaluation.hooks';

export const SubmitForEvaluation = ({
  onSuccess,
  loan,
}: {
  onSuccess: () => Promise<void>;
  loan: ILoanApi;
}) => {
  const { t } = useTranslation();

  const { evaluate, loading, success } = useSubmitForEvaluation({
    onSuccess,
    loan,
  });

  return (
    <>
      <ButtonFeedback
        css={{ mt: '$6', maxWidth: 256 }}
        onClick={evaluate}
        isLoading={loading}
        isSuccess={success}
        fullWidth
        data-testid="submit-for-evaluation"
      >
        {t('DealTimelineStep:submit_for_evaluation')}
      </ButtonFeedback>
    </>
  );
};
