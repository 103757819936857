import acquire from 'assets/brokers/acquire.png';
import boopos from 'assets/brokers/boopos.png';
import empireflippers from 'assets/brokers/empireflippers.png';
import feinternational from 'assets/brokers/feinternational.png';
import flippa from 'assets/brokers/flippa.png';
import latonas from 'assets/brokers/latonas.png';
import quietlight from 'assets/brokers/quietlight.png';
import websiteclosers from 'assets/brokers/websiteclosers.png';

import { IBroker } from 'lib/types';

export const BrokerImages: { [key: string]: string } = {
  boopos,
  acquire,
  empireflippers,
  feinternational,
  flippa,
  latonas,
  quietlight,
  websiteclosers,
};

export const BrokerImage = ({
  broker,
}: {
  broker: Pick<IBroker, 'name' | 'slug'>;
}) => {
  if (!BrokerImages[broker.slug]) {
    return <>{broker.name}</>;
  }

  return <img src={BrokerImages[broker.slug]} alt={broker.name} />;
};
